<template>
  <div class="index-page container-fluid">
    <h1>Error 404</h1>
    <p>
      Page not found
    </p>
  </div>
</template>

<script>

export default {

}
</script>

<style scoped>

</style>